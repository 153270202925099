<template>
  <div class="relative w-full" ref="dropdown-parent">
    <!-- OPENER (EDIT MODE) - For editing boards in SwipeFile -->
    <button v-if="isEditMode" @click="expandDropdown" tabindex="-1"
    class="w-full flex items-center gap-3 px-2 py-1.5 rounded-lg" :class="{'group transition-colors hover:bg-neutral-25': !noHoverOpener}">
      <BoardIcon class="text-icon-normal flex-shrink-0" />
      <div ref="opener-selected-boards-list-container" class="flex justify-start flex-grow min-w-0 overflow-x-hidden">
        <BaseText v-if="visibleSelectedBoards.opener.length === 0" type="body" size="sm" 
        class="text-text-normal py-0.5 transition-colors group-hover:text-text-muted truncate">
          <!-- Select to edit boards... -->
          {{ !isSavedAd && !newSavedAdId ? 'Select boards to save...' : 'Select to edit boards...' }}
        </BaseText>
        <!-- Selected boards list -->
        <div v-else class="flex items-center gap-1 w-full">
          <div v-for="board in visibleSelectedBoards.opener" :key="`opener-board-${board.id}`" style="border-radius: 4px;"
          class="flex items-center gap-1 pl-1.5 pr-0.5 py-0.5 bg-neutral-25 transition-colors group-hover:bg-neutral-50">
            <BaseText type="label" size="xs" class="text-text-muted">
              {{ board.name.slice(1) }}
            </BaseText>
            <button @click.stop.prevent="toggleSelectBoard(board)" tabindex="-1">
              <BoardRemoveIcon class="text-icon-normal w-5 h-5 p-0.5 transition-colors hover:text-icon-muted" />
            </button>
          </div>
          <!-- Hidden boards indicator -->
          <div v-if="hiddenSelectedBoards.opener.length > 0" style="border-radius: 4px;"
          class="px-1.5 py-1 bg-neutral-25 transition-colors group-hover:bg-neutral-50">
            <BaseText type="label" size="xs" class="text-text-muted">
              +{{ hiddenSelectedBoards.opener.length }}
            </BaseText>
          </div>
        </div>
      </div>
      <ChevronIcon class="text-icon-normal flex-shrink-0 transition-colors group-hover:text-icon-muted" />
    </button>
    <!-- OPENER (SAVE MODE) - For saving ads in Discovery and Spyder -->
    <button v-else class="relative w-full flex items-center gap-0.5 p-0.5 rounded-md transition-colors
    border border-transparent duration-300" :class="adCardHovered ? 'bg-neutral-10': 'bg-transparent'"
    @click="handleSaveAd" @mouseenter="buttonHovered = true" @mouseleave="buttonHovered = false" tabindex="-1">
      <!-- Backdrop div -->
      <transition name="fade">
        <div v-if="saveButtonColored" ref="save-ad-button-background"
        class="save-ad-button-background absolute left-0 right-0 top-0 bottom-0" 
        :class="[{'saved': saveSuccess}, `${getTheme}`]" />
      </transition>
      <!-- Save Icon -->
      <div class="flex-shrink-0 p-1.5 z-10">
        <SaveAdAnimatedIcon v-if="saveSuccess" :doAnimate="doAnimateSave" />
        <SaveAdIcon v-else class="transition-colors" 
        :class="saveButtonColored ? 'text-white' : 'text-icon-normal'" />
      </div>
      <!-- Save Text / Loading spinner -->
      <div class="relative flex-grow flex items-center justify-center z-10">
        <transition name="quickfade">
          <BaseText v-if="!isSaving" type="label" size="sm" 
          :class="saveButtonColored ? 'text-white' : 'text-text-muted'"
          class="absolute left-1/2 transform -translate-x-1/2 transition-colors w-max">
            {{ saveSuccess ? 'Saved' : 'Save to Swipe File' }}
          </BaseText>
        </transition>
        <transition name="quickfade">
          <SaveAdLoadingAnimatedIcon v-if="isSaving" class="absolute left-1/2 transform -translate-x-1/2" />
        </transition>
      </div>
      <!-- Extra button actions -->
      <div class="relative flex-shrink-0 w-8 h-8">
        <!-- Open in SwipeFile button -->
        <transition name="quickfade">
          <router-link v-if="saveSuccess && newSavedAdId" :to="{ name: 'LibraryView', query: { ad: newSavedAdId }}"
          class="absolute left-0 right-0 top-0 bottom-0 flex-shrink-0 p-1.5 rounded z-10 
          transition-colors duration-300 bg-transparent hover:bg-neutral-alpha-50">
            <OpenExternalIcon class="text-white" />
          </router-link>
        </transition>
        <!-- Open boards dropdown button -->
        <transition name="quickfade">
          <button v-if="!saveSuccess || !newSavedAdId" @click.stop.prevent="expandDropdown" tabindex="-1"
          class="absolute left-0 right-0 top-0 bottom-0 flex-shrink-0 p-1.5 rounded z-10 
          transition-colors duration-300 bg-transparent hover:bg-neutral-alpha-100">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
            class="transition-colors" :class="coloredByDefault || buttonHovered || isSaving ? 'text-white' : 'text-icon-normal'">
              <path d="M13.25 8.75L10 12.25L6.75 8.75" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </transition>
      </div>
    </button>
    <!-- Save Ad Dropdown -->
    <transition name="dropdown">
      <div v-if="dropdownOpen" v-on-clickaway="collapseDropdown" ref="dropdown"
      class="dropdown absolute left-0 -top-0.5 right-0 px-2 pt-2 pb-px rounded-lg overflow-hidden">
        <div class="dropdown-backdrop absolute top-0 bottom-0 left-0 right-0 rounded-lg" />
        <!-- MAIN MENU VIEW -->
        <transition name="mainmenu">
          <div v-show="!selectedFolder" class="flex flex-col w-full">
            <div class="w-full flex items-center gap-2">
              <!-- Search Input -->
              <div class="search-container group flex flex-grow items-center gap-1.5 px-2 py-1.5 rounded-md min-w-0 caret-white" 
              :class="{'focused': searchFocused}">
                <SearchIcon className="search-icon flex-shrink-0" />
                <input ref="search-input" placeholder="Search..." autocomplete="off" type="text" spellcheck="false"
                  class="input search-input min-w-0 bg-transparent text-sm text-white"
                  v-model="searchQuery" @focus="searchFocused = true, navIndex = null" @blur="searchFocused = false"
                  @keydown.down.prevent @keydown.up.prevent @keydown.enter.prevent @keydown.escape.prevent @keydown.tab.prevent
                />
              </div>
              <!-- '+ New' Button -->
              <button class="new-button group flex items-center gap-1 py-1.5 pl-1.5 pr-3 rounded-md flex-shrink-0"
              @click="toggleShowQuickAdd">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M10 5.625V10M10 10V14.375M10 10H5.625M10 10H14.375" stroke="currentColor" stroke-width="1.25" stroke-linecap="round"/>
                </svg>
                <BaseText type="label" size="sm">
                  New
                </BaseText>
              </button>
            </div>
            <!-- Quick add buttons/inputs -->
            <div class="relative w-full mt-2 overflow-hidden" style="transition: height 150ms ease-in-out, opacity 150ms ease-in-out;" 
            :style="{height: showQuickAdd ? '40px' : '0px', opacity: showQuickAdd ? '1' : '0'}">
              <transition name="quickinput">
                <!-- Create new folder -->
                <div v-show="!newBoardExpanded" class="new-folder absolute left-0 top-0 rounded-md flex items-center"
                :class="newFolderExpanded ? 'expanded' : 'cursor-pointer'"
                @click="expandNewFolderInput(true)" v-on-clickaway="() => { expandNewFolderInput(false) }">
                  <div ref="quick-folder-content" class="content flex items-center gap-1.5 w-full">
                    <div v-if="creatingFolder" class="relative bottom-px w-4 h-4 mx-0.5 flex-shrink-0">
                      <BaseLoadingSpinner small />
                    </div>
                    <NewFolderIcon v-else class="flex-shrink-0"/>
                    <BaseText v-if="!newFolderExpanded" type="label" size="sm" class="text whitespace-nowrap">
                      New Folder
                    </BaseText>
                    <input v-if="newFolderExpanded" class="input quick-add-input flex-grow min-w-0 caret-white" 
                    ref="new-folder-input" placeholder="Folder Name" style="transition: width 300ms ease-in-out"
                    autocomplete="off" type="text" spellcheck="false" v-model="newFolderName"
                    @keydown.enter.prevent="handleCreateFolder"/>
                    <transition name="checkmark">
                      <button v-if="newFolderExpanded" class="flex-shrink-0 mr-2 ml-auto"
                      @click="handleCreateFolder">
                        <NewCheckmarkIcon class="text-white"/>
                      </button>
                    </transition>
                  </div>
                </div>
              </transition>
              <transition name="quickinput">
                <!-- Create new board -->
                <div v-show="!newFolderExpanded" class="new-board absolute right-0 top-0 rounded-md flex items-center"
                :class="newBoardExpanded ? 'expanded' : 'cursor-pointer'"
                @click="expandNewBoardInput(true)" v-on-clickaway="() => { expandNewBoardInput(false) }">
                  <div ref="quick-board-content" class="content flex items-center gap-1.5 w-full">
                    <div v-if="creatingBoard" class="relative bottom-px w-4 h-4 mx-0.5 flex-shrink-0">
                      <BaseLoadingSpinner small />
                    </div>
                    <NewBoardIcon v-else class="flex-shrink-0" />
                    <BaseText v-if="!newBoardExpanded" type="label" size="sm" class="text whitespace-nowrap">
                      New Board
                    </BaseText>
                    <input v-if="newBoardExpanded" class="input quick-add-input flex-grow min-w-0 caret-white" 
                    ref="new-board-input" v-model="newBoardName" placeholder="Board Name" style="transition: width 300ms ease-in-out"
                    autocomplete="off" type="text" spellcheck="false" 
                    @input="boardNameInputChanged"
                    @keydown.enter.prevent="handleCreateBoard"/>
                    <transition name="checkmark">
                      <button v-if="newBoardExpanded" class="flex-shrink-0 mr-2 ml-auto"
                      @click="handleCreateBoard">
                        <NewCheckmarkIcon class="text-white"/>
                      </button>
                    </transition>
                  </div>
                </div>
              </transition>
            </div>
            <!-- Currently selected boards list -->
            <div class="w-full overflow-hidden" style="transition: height 150ms ease-in-out"
            :style="{height: selectedBoardsTwoRows ? '78px' : visibleSelectedBoards.dropdown.length > 0 ? '44px' : '0px'}">
              <div ref="selected-boards-list-container" class="flex flex-wrap gap-1.5 w-full p-2">
                <div v-for="board in visibleSelectedBoards.dropdown" :key="`selected-board-${board.id}`"
                class="flex items-center flex-nowrap p-0.5 rounded-md bg-primary-blue-100">
                  <BaseText type="body" size="sm" class="flex-shrink text-white px-1.5 py-0.5 min-w-0 truncate" style="max-width: 225px">
                    {{ board.name.slice(1) }}
                  </BaseText>
                  <button class="group p-1 transition-colors hover:bg-neutral-alpha-50" style="border-radius: 4px"
                  @click="toggleSelectBoard(board)">
                    <BoardRemoveIcon class="text-white opacity-60 transition-opacity group-hover:opacity-100" />
                  </button>
                </div>
                <!-- Hidden selected boards indicator ('+x') -->
                <button v-if="hiddenSelectedBoards.dropdown.length > 0" ref="hidden-boards-indicator"
                class="p-0.5 rounded-md bg-primary-blue-100 transition" :class="showHiddenBoardsPreview ? 'bg-opacity-60' : 'hover:bg-opacity-80'"
                @click="toggleHiddenBoardsPreview">
                  <BaseText type="body" size="sm" class="text-white px-1.5 py-0.5">
                    +{{ hiddenSelectedBoards.dropdown.length }}
                  </BaseText>
                </button>
              </div>
            </div>
            <!-- RESULTS LIST -->
            <div class="relative w-full focus:outline-none">
              <transition name="fade">
                <div v-if="listOverflows && !isScrollTop" class="top-fade-overlay absolute -top-px left-0 right-0 h-6 z-10 pointer-events-none" />
              </transition>
              <div ref="main-menu-results" class="flex flex-col w-full overflow-y-scroll scrollbar-hide pb-1.5 focus:outline-none" 
              :style="{maxHeight: listExpanded ? `${adjustedResultsMaxHeight}px` : '0px', transition: `max-height ${resultsTransitionDur}ms ease-in-out`}">
                <!-- Recent Boards (only displays when there's no text search) -->
                <div v-if="!searchQuery && recentBoards.length > 0" class="flex flex-col gap-1 w-full">
                  <!-- Section Divider -->
                  <div class="flex items-center gap-3 px-2 mt-2 pb-0.5">
                    <BaseText type="body" size="sm" class="text-white">
                      Recent Boards
                    </BaseText>
                    <div class="flex-grow h-px bg-neutral-alpha-50" />
                  </div>
                  <!-- Recent Boards List -->
                  <SaveAdBoardRow v-for="board in recentBoards" 
                    :key="`recent-board-${board.id}`"
                    :ref="`recent-board-${board.id}`"
                    :board="board"
                    :isSelected="selectedBoards.some(selectedBoard => selectedBoard.id === board.id)"
                    :isCurrentNav="currentNavItem?.id === board.id"
                    @selected="toggleSelectBoard(board)"
                    @mouseenter.native="() => { if (mouseMoved) navIndex = null }"
                  />
                </div>
                <!-- Folder Results -->
                <div v-if="!searchQuery || (searchQuery && folderResults.length > 0)" class="flex flex-col gap-1 w-full">
                  <!-- Section Divider -->
                  <div class="flex items-center gap-3 px-2 mt-2 pb-1">
                    <BaseText type="body" size="sm" class="text-white">
                      {{ searchQuery ? 'Folder Results' : 'Folders' }}
                    </BaseText>
                    <div class="flex-grow h-px bg-neutral-alpha-50" />
                  </div>
                  <!-- Folders List -->
                  <button v-for="folder in folderResults" :key="`folder-${folder.id}`" :ref="`folder-${folder.id}`"
                  class="group flex items-center gap-3 flex-nowrap py-2 pl-2 pr-3 rounded-md transition-colors min-w-0"
                  :class="getFolderItemColorClasses(folder.id, 'bg-transparent', 'bg-neutral-alpha-25', true)"
                  @click.stop.prevent="enterFolder(folder)" @mouseenter="() => { if (mouseMoved) navIndex = null }">
                    <StarredIcon v-if="folder.id === 'starred'" class="transition-colors flex-shrink-0"
                    :class="getFolderItemColorClasses(folder.id, 'text-neutral-malpha-650', 'text-white')" />
                    <FolderClosedIcon v-else class="transition-colors flex-shrink-0"
                    :class="getFolderItemColorClasses(folder.id, 'text-neutral-malpha-650', 'text-white')" />
                    <div class="flex-grow flex items-center gap-2.5 min-w-0 flex-nowrap">
                      <BaseText type="body" size="sm" class="flex-shrink text-left transition-colors min-w-0 truncate"
                      :class="getFolderItemColorClasses(folder.id, 'text-neutral-malpha-800', 'text-white')">
                        {{ folder.name }}
                      </BaseText>
                      <div v-if="folder.id !== 'starred' && folder.numSelected > 0" class="px-2.5 py-0.5 rounded-full bg-primary-blue-100">
                        <BaseText type="label" size="xs" class="text-white whitespace-nowrap">
                          {{ folder.numSelected }} Selected
                        </BaseText>
                      </div>
                    </div>
                    <BaseText type="body" size="xs" class="transition-colors min-w-max"
                    :class="getFolderItemColorClasses(folder.id, 'text-neutral-malpha-650', 'text-white')">
                      {{ folder?.boards?.length || 0 }} {{ folder?.boards?.length === 1 ? 'board' : 'boards' }}
                    </BaseText>
                  </button>
                </div>
                <!-- Board Results -->
                <div v-if="searchQuery && boardResults.length > 0" class="flex flex-col gap-1 w-full">
                  <!-- Section Divider -->
                  <div class="flex items-center gap-3 px-2 mt-2 pb-1">
                    <BaseText type="body" size="sm" class="text-white">
                      {{ searchQuery ? 'Board Results' : 'Boards' }}
                    </BaseText>
                    <div class="flex-grow h-px bg-neutral-alpha-50" />
                  </div>
                  <!-- Boards List -->
                  <SaveAdBoardRow v-for="board in boardResults" 
                    :key="`board-${board.id}`"
                    :ref="`board-${board.id}`"
                    :board="board"
                    :isSelected="selectedBoards.some(selectedBoard => selectedBoard.id === board.id)"
                    :isCurrentNav="currentNavItem?.id === board.id"
                    :keyboardNavActive="navIndex !== null"
                    @selected="toggleSelectBoard(board)"
                    @mouseenter.native="() => { if (mouseMoved) navIndex = null }"
                  />
                </div>
                <!-- No Search Results State -->
                <div v-if="searchQuery && !folderResults.length && !boardResults.length"
                class="flex flex-col items-center py-5 w-full">
                  <img :src="noResultsImgSrc" style="width: 60px; height: 60px;">
                  <BaseText type="label" size="sm" class="text-white mt-3">
                    No results found
                  </BaseText>
                  <BaseText type="body" size="sm" class="text-neutral-alpha-650 mt-1" style="font-weight: 300 !important">
                    Try modifying your search
                  </BaseText>
                </div>
              </div>
              <transition name="fade">
                <div v-if="listOverflows && !isMaxScroll" class="bottom-fade-overlay absolute -bottom-px left-0 right-0 h-6 z-10 pointer-events-none" />
              </transition>
            </div>
          </div>
        </transition>
        <!-- FOLDER SUBMENU VIEW -->
        <transition name="submenu">
          <div v-if="selectedFolder" class="flex flex-col gap-0.5 w-full">
            <!-- Folder Header/Back button -->
            <div class="flex items-center gap-1 pl-1.5 pr-2 py-1">
              <button class="group p-1 flex-shrink-0" @click.stop.prevent="exitFolder">
                <ChevronIcon className="text-neutral-malpha-600 transform rotate-90 transition-colors group-hover:text-neutral-malpha-800" />
              </button>
              <StarredIcon v-if="selectedFolder.id === 'starred'" class="text-white flex-shrink-0 mr-0.5" />
              <FolderClosedIcon v-else class="text-white flex-shrink-0 mr-0.5" />
              <BaseText type="label" size="sm" class="text-white flex-grow truncate min-w-0">
                {{ selectedFolder.name }}
              </BaseText>
            </div>
            <!-- Folder Boards List -->
            <div class="relative w-full">
              <transition name="fade">
                <div v-if="listOverflows && !isScrollTop" class="top-fade-overlay absolute top-0 left-0 right-0 h-6 z-10 pointer-events-none" />
              </transition>
              <div ref="submenu-results" class="flex flex-col gap-1 w-full overflow-y-scroll scrollbar-hide pb-1.5" 
              :style="{maxHeight: `${RESULTS_MAX_HEIGHT + 2}px`}">
                <!-- Quick add board button/input -->
                <div class="group flex items-center gap-1.5 pl-2 py-1.5 pr-3 rounded-md transition-colors cursor-pointer"
                :class="newFolderBoardExpanded ? 'bg-neutral-alpha-50' : 'bg-neutral-alpha-25 hover:bg-neutral-alpha-50'"
                @click="expandNewFolderBoardInput" v-on-clickaway="() => { newFolderBoardExpanded = false }">
                  <div v-if="creatingBoard" class="relative bottom-px w-4 h-4 mx-0.5 flex-shrink-0">
                    <BaseLoadingSpinner small />
                  </div>
                  <svg v-else xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="transition-colors flex-shrink-0"
                  :class="newFolderBoardExpanded ? 'text-white' : 'text-neutral-malpha-700 group-hover:text-white'">
                    <path d="M10 5.625V10M10 10V14.375M10 10H5.625M10 10H14.375" stroke="currentColor" stroke-width="1.25" stroke-linecap="round"/>
                  </svg>
                  <input v-if="newFolderBoardExpanded" ref="new-folder-board-input" v-model="newFolderBoardName" type="text" autocomplete="off" spellcheck="false"
                  placeholder="Board Name" class="input fade-in new-folder-board flex-grow min-w-0 text-white caret-white text-sm"
                  @input="boardNameInputChanged"
                  @keydown.enter.prevent="handleCreateBoard" />
                  <BaseText v-else type="body" size="sm" class="flex-grow text-white fade-in">
                    New Board
                  </BaseText>
                  <transition name="checkmark">
                    <button v-if="newFolderBoardExpanded" class="flex-shrink-0"
                    @click="handleCreateBoard">
                      <NewCheckmarkIcon class="text-white" />
                    </button>
                  </transition>
                </div>
                <!-- Board items -->
                <SaveAdBoardRow v-for="board in selectedFolder.boards" 
                  :key="`board-${board.id}`"
                  :ref="`board-${board.id}`"
                  :board="board"
                  :isSelected="selectedBoards.some(selectedBoard => selectedBoard.id === board.id)"
                  :isCurrentNav="currentNavItem?.id === board.id"
                  :keyboardNavActive="navIndex !== null"
                  @selected="toggleSelectBoard(board)"
                  @mouseenter.native="() => { if (mouseMoved) navIndex = null }"
                />
                <!-- Empty folder state -->
                <div v-if="!selectedFolder.boards.length" class="flex flex-col items-center gap-2 w-full py-5 rounded-md mt-1"
                style="background: linear-gradient(180deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.02) 100%), rgba(255, 255, 255, 0.03);">
                  <img :src="emptyFolderImgSrc" style="width: 60px; height: 60px;">
                  <div class="flex flex-col items-center gap-1 w-full">
                    <BaseText type="label" size="sm" class="text-white">
                      Nothing here yet!
                    </BaseText>
                    <BaseText type="body" size="sm" class="text-neutral-alpha-650" style="font-weight: 300 !important;">
                      Create a board to start saving.
                    </BaseText>
                  </div>
                </div>
                <!-- <div class="w-full h-1 flex-shrink-0" /> -->
              </div>
              <transition name="fade">
                <div v-if="listOverflows && !isMaxScroll" class="bottom-fade-overlay absolute bottom-0 left-0 right-0 h-6 z-10 pointer-events-none" />
              </transition>
            </div>
          </div>
        </transition>
      </div>
    </transition>
    <!-- Hidden boards preview - positioned absolutely at component root level to avoid overflow cutoff -->
    <transition name="dropdown">
      <div v-if="showHiddenBoardsPreview && hiddenSelectedBoards.dropdown.length > 0" class="absolute pt-1.5 transform -translate-x-1/2 z-50" 
      :style="{ top: `${hiddenBoardsPreviewPosition.y}px`, left: `${hiddenBoardsPreviewPosition.x}px`, width: `${HIDDEN_BOARDS_PREVIEW_WIDTH}px`}"
      v-on-clickaway="() => {showHiddenBoardsPreview = false}">
        <div class="relative flex flex-col gap-1 p-1 rounded-lg w-full shadow-lg">
          <div class="hidden-boards-preview-bg absolute left-0 top-0 bottom-0 right-0 rounded-lg" style="z-index: -10" />
          <div v-for="board in hiddenSelectedBoards.dropdown" :key="`hidden-board-${board.id}`" 
          class="flex items-center justify-between flex-nowrap p-0.5 w-full">
            <BaseText type="label" size="xs" class="flex-shrink px-1.5 text-white min-w-0 truncate cursor-default">
              {{ board.name.slice(1) }}
            </BaseText>
            <button class="group p-0.5 transition-colors hover:bg-neutral-alpha-50" style="border-radius: 4px" 
            @click.stop.prevent="toggleSelectBoard(board)">
              <BoardRemoveIcon class="text-white opacity-60 transition-opacity group-hover:opacity-100" />
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway2'
import smoothReflow from 'vue-smooth-reflow'
import FirebaseAPI from '@/api/firebase'
import SaveAdBoardRow from './SaveAdBoardRow.vue'

// Icons
import BoardIcon from '../../globals/Icons/BoardIcon.vue'
import BoardSelectableIcon from '../../globals/Icons/BoardSelectableIcon.vue'
import ChevronIcon from '../../globals/Icons/ChevronIcon.vue'
import SearchIcon from '../../globals/Icons/SearchIcon.vue'
import FolderClosedIcon from '../../globals/Icons/FolderClosedIcon.vue'
import NewCheckmarkIcon from '../../globals/Icons/NewCheckmarkIcon.vue'
import StarredIcon from '../../globals/Icons/StarredIcon.vue'
import NewFolderIcon from '../../globals/Icons/NewFolderIcon.vue'
import NewBoardIcon from '../../globals/Icons/NewBoardIcon.vue'
import BoardRemoveIcon from '../../globals/Icons/BoardRemoveIcon.vue'
import SaveAdIcon from '../../globals/Icons/SaveAdIcon.vue'
import OpenExternalIcon from '../../globals/Icons/OpenExternalIcon.vue'
import SaveAdAnimatedIcon from '../../globals/Icons/SaveAdAnimatedIcon.vue'
import SaveAdLoadingAnimatedIcon from '../../globals/Icons/SaveAdLoadingAnimatedIcon.vue'

const RESULTS_MAX_HEIGHT = 336
const HIDDEN_BOARDS_PREVIEW_WIDTH = 144

export default {
  name: 'SaveAdDropdown',
  mixins: [clickaway, smoothReflow],
  components: {
    SaveAdBoardRow,
    BoardIcon,
    BoardSelectableIcon,
    ChevronIcon,
    SearchIcon,
    FolderClosedIcon,
    NewCheckmarkIcon,
    StarredIcon,
    NewFolderIcon,
    NewBoardIcon,
    BoardRemoveIcon,
    SaveAdIcon,
    SaveAdAnimatedIcon,
    OpenExternalIcon,
    SaveAdLoadingAnimatedIcon
  },
  props: {
    advertisement: {
      type: Object,
      required: true
    },
    isSavedAd: {
      type: Boolean,
      default: false
    },
    isEditMode: {
      type: Boolean,
      default: false
    },
    externalAdSavedId: {
      type: String,
      default: ''
    },
    noHoverOpener: {
      type: Boolean,
      default: false
    },
    adCardHovered: {
      type: Boolean,
      default: false
    },
    coloredByDefault: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // Data States
      selectedBoards: [],
      searchQuery: '',
      selectedFolder: null,
      newSavedAdId: null,
      isSaving: false,
      saveFailed: false,
      creatingBoard: false,
      creatingFolder: false,
      navIndex: null,
      prevNavIndex: null,

      // UI States
      buttonHovered: false,
      dropdownOpen: false,
      searchFocused: false,
      listExpanded: false,
      isScrollTop: true,
      isMaxScroll: false,
      listOverflows: false,
      saveSuccess: false,
      visibleSelectedBoards: {opener: [], dropdown: []},
      hiddenSelectedBoards: {opener: [], dropdown: []},
      showHiddenBoardsPreview: false,
      hiddenBoardsPreviewPosition: { x: 0, y: 0},
      selectedBoardsTwoRows: false,
      resultsTransitionDur: 100,
      mouseMoved: false,
      mouseMoveTimeout: null,
      doAnimateSave: true,
      emptyFolderImgSrc: require('../../../assets/images/empty-folder.png'),
      noResultsImgSrc: require('../../../assets/images/dropdown-no-results.png'),
      RESULTS_MAX_HEIGHT,
      HIDDEN_BOARDS_PREVIEW_WIDTH,

      // Quick Add Inputs
      showQuickAdd: false,
      newFolderExpanded: false,
      newBoardExpanded: false,
      newFolderBoardExpanded: false,
      newFolderName: '',
      newBoardName: '',
      newFolderBoardName: '',
      newFolderCollapsedPadding: '20px',
      newBoardCollapsedPadding: '20px',
    }
  },
  mounted () {
    this.setAdBoards()
    this.$nextTick(() => {
      requestAnimationFrame(() => {
        // We really want to make sure everything is loaded before we start checking for overflows
        setTimeout(() => {
          if (this.isEditMode) this.computeVisibleSelectedBoards(true)
        }, 100)
      })
    })

    // If mounting in Discovery or Spyder details drawer, we need to check if the ad is already saved
    if (this.externalAdSavedId.length) {
      this.doAnimateSave = false
      this.saveSuccess = true
      this.newSavedAdId = this.externalAdSavedId
    }

    // Load and cache the empty state images early (so we don't have dom pop-in)
    const emptyImg = new Image()
    emptyImg.src = this.emptyFolderImgSrc
    const noResultsImg = new Image()
    noResultsImg.src = this.noResultsImgSrc
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.handleKeyboardNavigation)
    const dropdown = this.$refs.dropdown
    if (dropdown) dropdown.removeEventListener('mousemove', this.handleMouseMove)
    const mainMenuResults = this.$refs['main-menu-results']
    if (mainMenuResults) mainMenuResults.removeEventListener('scroll', this.handleResultsScroll)
  },
  computed: {
    ...mapGetters('AdvertisementsModule', ['getExpandedDropdownId']),
    ...mapGetters('BoardsModule', ['getBoards', 'getFolders']),
    ...mapGetters('MiscModule', ['getTheme']),
    ...mapGetters('AuthModule', ['getUser', 'getTeam', 'getStarredBoards']),
    folders () {
      const starredFolder = {
        id: 'starred',
        name: 'Starred',
        boards: [...this.getStarredBoards].sort((a, b) => a.name.localeCompare(b.name)),
        numSelected: this.getStarredBoards.filter(board => this.selectedBoards.some(selectedBoard => selectedBoard.id === board.id)).length
      }
      const defaultFolderBoards = this.getBoards.filter(board => !this.getFolders.some(folder => folder?.boardIds?.includes(board.id)))
      const defaultFolder = {
        id: 'default',
        name: 'Default Folder',
        boards: defaultFolderBoards.sort((a, b) => a.name.localeCompare(b.name)),
        numSelected: defaultFolderBoards.filter(board => this.selectedBoards.some(selectedBoard => selectedBoard.id === board.id)).length
      }
      const builtInFolders = starredFolder.boards.length > 0 ? [starredFolder, defaultFolder] : [defaultFolder]
      return [
        ...builtInFolders, 
        ...(this.getFolders.map(folder => ({
          id: folder.id,
          name: folder.name,
          boards: this.getBoards.filter(board => folder?.boardIds?.includes(board.id)).map(board => board).sort((a, b) => a.name.localeCompare(b.name)),
          numSelected: folder?.boardIds?.filter(boardId => this.selectedBoards.some(selectedBoard => selectedBoard.id === boardId))?.length || 0
        })))]
    },
    folderResults () {
      const normalizedQuery = this.searchQuery.toLowerCase().trim()
      const normalizer = (str) => str.toLowerCase().trim()
      return computeSearchResults(this.folders, normalizer, normalizedQuery)
    },
    boardResults () {
      if (!this.searchQuery) return []
      const normalizedQuery = this.searchQuery.toLowerCase().replace(/[\s_]/g, "")
      const normalizer = (str) => str.slice(1).toLowerCase().replace(/[\s_]/g, "")
      return computeSearchResults(this.getBoards, normalizer, normalizedQuery)
    },
    recentBoards () {
      return this.getUser?.recentBoards
        ?.map(({ boardId }) => this.getBoards.find(board => board.id === boardId)) 
        ?.filter(board => board) || []
    },
    navigationItems () { // An array of all the items that can be navigated to via keyboard
      if (this.selectedFolder) {
        // If in a folder, we navigate the folder's boards
        return this.selectedFolder.boards.map(board => ({
          id: board.id,
          ref: `board-${board.id}`,
          type: 'board',
          action: () => {
            const boardElm = this.$refs[`board-${board.id}`][0].$el
            if (boardElm) boardElm.click()
          }
        }))
      }
      // Recent boards are only shown when there is no search query
      const recentBoards = !this.searchQuery
        ? this.recentBoards.map(board => ({
          id: board.id,
          ref: `recent-board-${board.id}`,
          type: 'board',
          action: () => {
            const boardElm = this.$refs[`recent-board-${board.id}`][0].$el
            if (boardElm) boardElm.click()
          }
        })) 
        : []
      const folderResults = this.folderResults.map(folder => ({
        id: folder.id,
        ref: `folder-${folder.id}`,
        type: 'folder',
        action: () => this.enterFolder(folder)
      }))
      const boardResults = this.boardResults.map(board => ({
        id: board.id,
        ref: `board-${board.id}`,
        type: 'board',
        action: () => {
          const boardElm = this.$refs[`board-${board.id}`][0].$el
          if (boardElm) boardElm.click()
        }
      }))
      return [...recentBoards, ...folderResults, ...boardResults]
    },
    currentNavItem () {
      return this.navIndex !== null ? this.navigationItems[this.navIndex] : null
    },
    adjustedResultsMaxHeight () {
      let height = RESULTS_MAX_HEIGHT
      if (this.selectedBoardsTwoRows) height -= 78
      else if (this.visibleSelectedBoards.dropdown.length > 0) height -= 44
      if (this.showQuickAdd) height -= 40
      return height
    },
    saveButtonColored () {
      return this.coloredByDefault || this.buttonHovered || this.saveSuccess || this.isSaving
    }
  },
  watch: {
    advertisement () {
      this.setAdBoards() // If ad changes from the parent, reset the selected boards
    },
    selectedBoards () {
      this.$nextTick(() => {
        requestAnimationFrame(() => {
          if (this.isEditMode) this.computeVisibleSelectedBoards(true)
          if (this.dropdownOpen) this.computeVisibleSelectedBoards()
        })
      })
    },
    searchQuery () {
      this.navIndex = null
      this.$nextTick(() => {
        this.determineListOverflows()
      })
    },
    externalAdSavedId (newId) {
      // We want saved state between ad card and details drawer to be in sync
      if (newId.length) {
        this.saveSuccess = true
        this.newSavedAdId = newId
      }
    },
    getExpandedDropdownId (newId) {
      // We globally store the expanded dropdown id to ensure only one dropdown is open at a time
      if (this.dropdownOpen && newId !== this.advertisement.id) this.collapseDropdown()
    }
  },
  methods: {
    ...mapActions('BoardsModule', ['fetchBoards', 'fetchFolders']),
    ...mapMutations('AdvertisementsModule', ['SET_EXPANDED_DROPDOWN_ID']),
    ...mapMutations('AuthModule', ['SET_USER', 'SET_STARRED_BOARDS']),

    // ================================================================================
    // ================================= DATA METHODS =================================
    // ================================================================================

    toggleSelectBoard (board) {
      if (this.selectedBoards.some(selectedBoard => selectedBoard.id === board.id)) {
        this.selectedBoards = this.selectedBoards.filter(selectedBoard => selectedBoard.id !== board.id)
      } else {
        this.selectedBoards = [...this.selectedBoards, board]
      }
      if (this.isSavedAd || this.newSavedAdId) this.handleUpdateAdBoards()
      else this.debouncedHandleSaveAd()
    },
    boardNameInputChanged (event) {
      const name = `${event.target.value.replace(/ /g, '_').toLowerCase()}`
      if (!this.selectedFolder) this.newBoardName = name
      else this.newFolderBoardName = name
    },
    debouncedHandleSaveAd: _.debounce(async function () {
      this.handleSaveAd()
    }, 1500),
    async handleSaveAd () {
      if (this.isSaving) {
        setTimeout(() => { this.handleUpdateAdBoards() }, 1000)
        return
      }
      if (this.saveSuccess) return

      try {
        this.isSaving = true
        const savedBoards = this.selectedBoards.map(board => board.id)
        const payload = {
          ...this.advertisement,
          board_ids: savedBoards,
          createdAt: +new Date()
        }

        const isSpyderView = this.$route.name === 'SpyderView'

        const newAdId = await FirebaseAPI.Advertisements.save(payload, this.getTeam?.id, isSpyderView)
        this.newSavedAdId = newAdId
        this.saveFailed = false
        this.saveSuccess = true
        this.updateRecentBoards()
        this.fetchBoards()
        this.$emit('adSaved', newAdId)
        this.$emit('updateBoards', savedBoards)

        this.$showAlert({
          type: 'success',
          message: 'Ad saved'
        })
      } catch (error) {
        this.saveFailed = true
        console.error(error)
        this.$showAlert({
          type: 'error',
          message: 'Failed to save ad. See console for details'
        })
      } finally {
        this.isSaving = false
      }
    },
    handleUpdateAdBoards: _.debounce(async function () {
      if (this.saveFailed) return
      if (this.isSaving) { 
        // If user buffered an update while still saving
        setTimeout(() => { this.handleUpdateAdBoards() }, 1000)
        return
      }

      try {
        const newBoards = this.selectedBoards.map(board => board.id)
        const payload = { board_ids: newBoards }

        const toUpdateId = this.newSavedAdId || this.advertisement.id
        await FirebaseAPI.Advertisements.update(toUpdateId, payload)
        this.updateRecentBoards()
        this.fetchBoards()
        this.$emit('updateBoards', newBoards)

        this.$showAlert({
          type: 'success',
          message: 'Boards updated'
        })
      } catch (error) {
        console.error(error)
        this.$showAlert({
          type: 'error',
          message: 'Failed to update boards. See console for details'
        })
        this.setAdBoards()
      }
    }, 1500),
    async handleCreateBoard () {
      if (this.creatingBoard || (this.newBoardName === '' && this.newFolderBoardName === '')) return
      try {
        this.creatingBoard = true
        const payload = {
          ads: [],
          description: '',
          name: `#${this.selectedFolder ? this.newFolderBoardName : this.newBoardName}`,
        }
        if (this.getTeam) payload.teamId = this.getTeam.id

        const inStarredFolder = this.selectedFolder?.id === 'starred'
        const boardFolder = (this.selectedFolder && this.selectedFolder.id !== 'default' && !inStarredFolder)
          ? this.selectedFolder
          : null

        const newBoardId = await FirebaseAPI.Boards.create(payload, boardFolder)
        await this.fetchBoards()
        await this.fetchFolders()

        if (inStarredFolder) {
          // If we added a board from the starred folder, update the user's starred boards
          const updatedUser = { ...this.getUser }
          if (!updatedUser.starredBoards) updatedUser.starredBoards = []
          updatedUser.starredBoards.push(newBoardId)

          await FirebaseAPI.Users.update(this.getUser.user_id, { starredBoards: updatedUser.starredBoards })

          this.SET_USER(updatedUser)
          this.SET_STARRED_BOARDS(this.getBoards.filter(board => updatedUser.starredBoards.includes(board.id)))
        }

        // If we added a board from within a folder, update the selected folder
        if (this.selectedFolder) this.selectedFolder = this.folders.find(folder => folder.id === this.selectedFolder.id)

        this.$showAlert({
          type: 'success',
          message: 'Board created'
        })
      } catch (error) {
        console.error(error)
        this.$showAlert({
          type: 'error',
          message: 'Failed to create board. See console for details'
        })
      } finally {
        this.creatingBoard = false
        this.expandNewBoardInput(false)
        this.newFolderBoardExpanded = false
        this.newBoardName = ''
        this.newFolderBoardName = ''
      }
    },
    async handleCreateFolder () {
      if (this.creatingFolder || this.newFolderName === '') return
      try {
        this.creatingFolder = true
        const payload = {
          name: this.newFolderName,
          boardIds: [],
          createdAt: +new Date()
        }
        if (this.getTeam) payload.teamId = this.getTeam.id

        await FirebaseAPI.Folders.create(payload)
        await this.fetchFolders()

        this.$showAlert({
          type: 'success',
          message: 'Folder created'
        })
      } catch (error) {
        console.error(error)
        this.$showAlert({
          type: 'error',
          message: 'Failed to create folder. See console for details'
        })
      } finally {
        this.creatingFolder = false
        this.expandNewFolderInput(false)
        this.newFolderName = ''
      }
    },
    async updateRecentBoards () {
      const newlyAppliedBoards = this.selectedBoards
        .filter(({ id }) => 
          !this.advertisement?.board_ids?.some(boardId => boardId === id)
          && !this.getUser?.recentBoards?.some(({ boardId }) => boardId === id)
        )
        .map((board, index) => ({
          boardId: board.id,
          date: +new Date() - index 
        }))
      if (newlyAppliedBoards.length === 0) return

      const updatedRecentBoards = [...newlyAppliedBoards, ...(this.getUser?.recentBoards || [])]
        .sort((a, b) => b.date - a.date).slice(0, 3)

      try {
        await FirebaseAPI.Users.update(this.getUser.user_id, { recentBoards: updatedRecentBoards })
        const updatedUser = { ...this.getUser }
        updatedUser.recentBoards = updatedRecentBoards
        this.SET_USER(updatedUser)
      } catch (error) {
        console.error(error)
        this.$showAlert({
          type: 'error',
          message: 'Failed to update recent boards. See console for details'
        })
      }
    },
    setAdBoards () {
      if (!this.advertisement.board_ids) return
      this.selectedBoards = this.getBoards.filter((board) =>
        this.advertisement?.board_ids?.includes(board.id)
      )
    },

    // ==============================================================================
    // ================================= UI METHODS =================================
    // ==============================================================================

    expandDropdown () {
      this.dropdownOpen = true
      this.$emit('dropdownOpened')
      this.SET_EXPANDED_DROPDOWN_ID(this.advertisement.id)
      this.mouseMoved = false
      this.$nextTick(() => {
        this.$refs['search-input'].focus()
        const mainMenuResults = this.$refs['main-menu-results']
        mainMenuResults.addEventListener('scroll', this.handleResultsScroll)
        this.determineListOverflows()
        this.handleResultsScroll(null, mainMenuResults)
        this.computeQuickAddButtonPadding()
        this.computeVisibleSelectedBoards()
        window.addEventListener('keydown', this.handleKeyboardNavigation)
        this.$refs['dropdown'].addEventListener('mousemove', this.handleMouseMove)

        // Configure smooth reflow on the dropdown
        this.$smoothReflow({
          el: this.$refs.dropdown,
          property: 'height',
          transition: 'height 150ms ease-in-out',
        })

        setTimeout(() => { this.listExpanded = true }, 1)
        setTimeout(() => { this.resultsTransitionDur = 150 }, 110)
      })
    },
    collapseDropdown () {
      this.$refs['main-menu-results']?.removeEventListener('scroll', this.handleResultsScroll)
      this.$refs['dropdown']?.removeEventListener('mousemove', this.handleMouseMove)
      this.$unsmoothReflow({ el: this.$refs.dropdown })
      this.listExpanded = false
      this.dropdownOpen = false
      this.searchQuery = ''
      this.searchFocused = false
      this.selectedFolder = null
      this.showQuickAdd = false
      this.expandNewFolderInput(false)
      this.expandNewBoardInput(false)
      this.showHiddenBoardsPreview = false
      this.newFolderBoardExpanded = false
      this.resultsTransitionDur = 100
      this.mouseMoved = false
      window.removeEventListener('keydown', this.handleKeyboardNavigation)
      this.$emit('dropdownClosed')
    },
    enterFolder (folder) {
      this.prevNavIndex = this.navIndex
      this.navIndex = null
      this.selectedFolder = folder
      this.showHiddenBoardsPreview = false
      this.pauseDocumentPointerEvents()
      if (this.showQuickAdd) {
        this.showQuickAdd = false
        this.expandNewFolderInput(false)
        this.expandNewBoardInput(false)
      }
      this.$nextTick(() => {
        this.determineListOverflows()
        const submenuResults = this.$refs['submenu-results']
        this.handleResultsScroll(null, submenuResults)
        submenuResults.addEventListener('scroll', this.handleResultsScroll)
      })
    },
    exitFolder () {
      this.navIndex = this.prevNavIndex
      this.selectedFolder = null
      this.newFolderBoardExpanded = false
      this.pauseDocumentPointerEvents()
      this.$refs['submenu-results'].removeEventListener('scroll', this.handleResultsScroll)
      this.$nextTick(() => {
        this.determineListOverflows()
        this.handleResultsScroll(null, this.$refs['main-menu-results'])
        this.computeVisibleSelectedBoards()
      })
    },
    handleKeyboardNavigation (event) {
      const validKeys = ['ArrowDown', 'ArrowUp', 'Enter', 'Escape', 'Tab']
      if (!validKeys.includes(event.key)) return
      event.preventDefault()
      switch (event.key) {
        case "ArrowDown":
          this.navigateNext(); break
        case "ArrowUp":
          this.navigatePrev(); break
        case "Enter":
          this.selectNavItem(); break
        case "Escape":
          this.escapeNavigation(); break
      }
    },
    navigateNext () {
      if (this.navIndex === null || this.navIndex < this.navigationItems.length - 1) {
        this.navIndex = this.navIndex === null ? 0 : this.navIndex + 1
        this.scrollToItem(this.currentNavItem.ref)
      }
    },
    navigatePrev () {
      if (this.navIndex === 0) {
        this.navIndex = null
      } else if (this.navIndex !== null) {
        this.navIndex--
        this.scrollToItem(this.currentNavItem.ref)
      }
    },
    selectNavItem () {
      if (this.currentNavItem) this.currentNavItem.action()
    },
    escapeNavigation () {
      if (this.selectedFolder) this.exitFolder()
      else this.collapseDropdown()
    },
    scrollToItem (ref) {
      this.$nextTick(() => {
        let item = this.$refs[ref][0]
        if (!item) return

        if (item.$el) item = item.$el // In case of a Vue component
        const itemRect = item.getBoundingClientRect()
        const container = this.selectedFolder ? this.$refs['submenu-results'] : this.$refs['main-menu-results']
        const containerRect = container.getBoundingClientRect()

        // Calculate the scroll position
        let scrollPosition = container.scrollTop
        const offset = 24
        const overflowTop = containerRect.top - itemRect.top
        const overflowBottom = itemRect.bottom - containerRect.bottom
        if (this.navIndex === 0) {
          scrollPosition = 0
        } else if (this.navIndex === this.navigationItems.length - 1) {
          scrollPosition = container.scrollHeight
        } else if (overflowTop > 0) {
          scrollPosition = container.scrollTop - overflowTop - offset
        } else if (overflowBottom > 0) {
          scrollPosition = container.scrollTop + overflowBottom + offset
        }
        container.scrollTo({ top: scrollPosition, behavior: 'smooth' })
      })
    },
    handleMouseMove () {
      // We only want hovering to clear the keyboard nav index when the user is actively moving the mouse,
      // so we track whether they've moved their mouse (inside the dropdown) within the last 500ms
      if (this.mouseMoved && this.mouseMovedTimeout) {
        clearTimeout(this.mouseMovedTimeout)
      }
      else this.mouseMoved = true
      this.mouseMovedTimeout = setTimeout(() => {
        this.mouseMoved = false
      }, 500)
    },
    toggleShowQuickAdd () {
      this.showQuickAdd = !this.showQuickAdd
      this.expandNewFolderInput(false)
      this.expandNewBoardInput(false)
      this.determineListOverflows()
    },
    toggleHiddenBoardsPreview () {
      this.showHiddenBoardsPreview = !this.showHiddenBoardsPreview
      if (!this.showHiddenBoardsPreview) return

      // We have to compute the position relative to the parent container
      const parentRect = this.$refs['dropdown-parent'].getBoundingClientRect()
      const indicatorRect = this.$refs['hidden-boards-indicator'].getBoundingClientRect()

      // Adjust for possible viewport overflow on the right side
      const needsAdjust = window.innerWidth - indicatorRect.right < HIDDEN_BOARDS_PREVIEW_WIDTH / 2 + indicatorRect.width / 2
      const xPos = needsAdjust
        ? indicatorRect.left - parentRect.left - 4
        : indicatorRect.left - parentRect.left + (indicatorRect.width / 2)

      this.hiddenBoardsPreviewPosition = {
        x: xPos,
        y: indicatorRect.top - parentRect.top + indicatorRect.height
      }
    },
    handleResultsScroll (event, el = null) {
      const list = el || event.target
      this.isScrollTop = list.scrollTop === 0
      this.isMaxScroll = list.scrollHeight - list.scrollTop === list.clientHeight
    },
    determineListOverflows () {
      const isMainView = !this.selectedFolder
      const list = isMainView ? this.$refs['main-menu-results'] : this.$refs['submenu-results']
      this.listOverflows = list.scrollHeight > (isMainView ? this.adjustedResultsMaxHeight : RESULTS_MAX_HEIGHT + 2)
    },
    computeVisibleSelectedBoards (isOneRow = false) {
      if (!isOneRow) this.selectedBoardsTwoRows = false

      // Compute the rendered widths for each selected board item
      const compNodeClasses = isOneRow ? ['pl-1.5', 'pr-0.5'] : ['px-0.5']
      const textClasses = isOneRow ? ['text-xs'] : ['text-sm', 'px-1.5']
      let computedBoardWidths = []
      this.selectedBoards.forEach(board => {
        const compNode = document.createElement('span')
        compNode.classList.add(...compNodeClasses, 'flex', 'items-center', 'flex-nowrap', 'opacity-0', 'w-min')
        const text = document.createElement('span')
        text.classList.add(...textClasses, 'flex-shrink', 'min-w-0', 'truncate')
        text.style.maxWidth = '225px'
        text.textContent = board.name.slice(1)
        compNode.appendChild(text)
        document.body.appendChild(compNode)
        const boardWidth = compNode.getBoundingClientRect().width + 24 // +24 for close button
        computedBoardWidths.push({ board, width: boardWidth })
        document.body.removeChild(compNode)
      })

      // Sort the boards by width (asc) - fits the smallest boards first
      computedBoardWidths = computedBoardWidths.sort((a, b) => a.width - b.width)

      // Determine which boards will fit and which will be hidden (We want to allow at most two rows of selected boards)
      const ref = isOneRow ? 'opener-selected-boards-list-container' : 'selected-boards-list-container'
      const padding = isOneRow ? 0 : 16
      let containerWidth = this.$refs[ref].getBoundingClientRect().width - padding
      const gap = isOneRow ? 4 : 6
      const hiddenIndicator = isOneRow ? 30 : 46
      const visibleSelectedBoards = []
      const hiddenSelectedBoards = []

      let totalWidth = 0
      let currRow = 0
      for (let i = 0; i < computedBoardWidths.length; i++) {
        const {board, width} = computedBoardWidths[i]
        const isLastItem = i === computedBoardWidths.length - 1 && hiddenSelectedBoards.length === 0
        const maxWidth = isOneRow
          ? isLastItem ? containerWidth : containerWidth - hiddenIndicator
          : (currRow === 0 || isLastItem) ? containerWidth : containerWidth - hiddenIndicator
        if (totalWidth + width <= maxWidth) {
          totalWidth += width + gap
          visibleSelectedBoards.push(board)
        } else if (!isOneRow && currRow === 0) {
          this.selectedBoardsTwoRows = true
          currRow++
          totalWidth = width + gap
          visibleSelectedBoards.push(board)
        } else {
          hiddenSelectedBoards.push(board)
        }
      }
      if (!isOneRow && !hiddenSelectedBoards.length) this.showHiddenBoardsPreview = false

      const stateKey = isOneRow ? 'opener' : 'dropdown'
      this.visibleSelectedBoards[stateKey] = visibleSelectedBoards
      this.hiddenSelectedBoards[stateKey] = hiddenSelectedBoards
    },
    getFolderItemColorClasses (id, baseColorClass, hoverColorClass, isParent = false) {
      return this.currentNavItem?.id === id
        ? hoverColorClass
        : this.navIndex === null
          ? `${baseColorClass} ${isParent ? 'hover' : 'group-hover'}:${hoverColorClass}`
          : baseColorClass
    },

    // ----- Quick Add Inputs -----
    expandNewFolderInput (expanded) {
      this.newFolderExpanded = expanded
      const button = this.$refs['quick-folder-content']
      if (expanded) {
        this.expandNewBoardInput(false)
        button.style.paddingLeft = '8px'
        this.$nextTick(() => {
          this.$refs['new-folder-input'].focus()
        })
      } else {
        button.style.paddingLeft = this.newFolderCollapsedPadding
        this.newFolderName = ''
      }
    },
    expandNewBoardInput (expanded) {
      this.newBoardExpanded = expanded
      const button = this.$refs['quick-board-content']
      if (expanded) {
        this.expandNewFolderInput(false)
        button.style.paddingLeft = '8px'
        this.$nextTick(() => {
          this.$refs['new-board-input'].focus()
        })
      } else {
        button.style.paddingLeft = this.newBoardCollapsedPadding
        this.newBoardName = ''
      }
    },
    expandNewFolderBoardInput () {
      this.newFolderBoardName = ''
      this.newFolderBoardExpanded = true
      this.$nextTick(() => {
        this.$refs['new-folder-board-input'].focus()
      })
    },
    computeQuickAddButtonPadding () {
      // Since we are transitioning left padding when these buttons expand, we need to compute it
      const buttons = [this.$refs['quick-folder-content'], this.$refs['quick-board-content']]
      buttons.forEach(button => {
        if (button) {
          const parentWidth = button.offsetWidth;
          const contentWidth = button.lastElementChild.offsetWidth + 26;
          const padding = `${Math.round((parentWidth - contentWidth) / 2)}px`
          button.style.paddingLeft = padding
          if (button === this.$refs['quick-folder-content']) {
            this.newFolderCollapsedPadding = padding
          } else {
            this.newBoardCollapsedPadding = padding
          }
        }
      })
    },
    pauseDocumentPointerEvents () {
      // We pause pointer events on the page when transitioning into and out of folders.
      // This prevents javascript events from firing while the dropdown is transitioning (they can cause transition snags)
      document.body.classList.add('pointer-events-none')
      setTimeout(() => {
        document.body.classList.remove('pointer-events-none')
      }, 200)
    },
    // =======================================
  }
}

const computeSearchResults = (searchItems, normalizer, normalizedQuery) => {
  let searchResults = []
  searchItems.forEach(item => {
    const normalizedItemName = normalizer(item.name)
    if (normalizedItemName.includes(normalizedQuery))
      searchResults.push({ item, normalizedName: normalizedItemName })
  })

  // Sort by closeness of match
  return searchResults.sort((a, b) => {
    const ratioA = normalizedQuery.length / a.normalizedName.length
    const ratioB = normalizedQuery.length / b.normalizedName.length
    return ratioB - ratioA
  }).map(({ item }) => item)
}
</script>

<style scoped>
@property --save-gradient-color {
  syntax: '<color>';
  initial-value: #6534DF;
  inherits: false;
}
@property --save-shadow-color {
  syntax: '<color>';
  initial-value: #6336D3;
  inherits: false;
}

.save-ad-button-background {
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 6px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) 100%), var(--save-gradient-color);
  box-shadow: 0px 0px 0px 1px var(--save-shadow-color), 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset;
  transition: --save-gradient-color 500ms ease-in-out, --save-shadow-color 500ms ease-in-out;
}
.save-ad-button-background.blue {
  --save-gradient-color: #006BFF;
  --save-shadow-color: #0063F4;
}
.save-ad-button-background.purple {
  --save-gradient-color: #6534DF;
  --save-shadow-color: #6336D3;
}
.save-ad-button-background.red {
  --save-gradient-color: #FF5453;
  --save-shadow-color: #f45555;
}
.save-ad-button-background.purple.saved, .save-ad-button-background.red.saved, .save-ad-button-background.blue.saved {
  --save-gradient-color: #00AB75;
  --save-shadow-color: #00B07E;
}

.dropdown {
  box-shadow: 0px 12px 12px -6px rgba(6, 7, 16, 0.02), 0px 6px 6px -3px rgba(6, 7, 16, 0.04), 0px 3px 3px -1.5px rgba(6, 7, 16, 0.04), 0px 1.5px 1.5px -0.75px rgba(6, 7, 16, 0.08);
  z-index: 50;
}
.dropdown-backdrop {
  z-index: -10;
  background-color: #131119;
  box-shadow: 0px 12px 12px -6px rgba(6, 7, 16, 0.02), 0px 6px 6px -3px rgba(6, 7, 16, 0.04), 0px 3px 3px -1.5px rgba(6, 7, 16, 0.04), 0px 1.5px 1.5px -0.75px rgba(6, 7, 16, 0.08);
}
.search-container {
  background-color: rgba(255, 255, 255, 0.08);
  transition: background-color 150ms ease-in-out;
}
.search-container:hover {
  background-color: rgba(255, 255, 255, 0.12);
}
.search-container.focused {
  background-color: rgba(255, 255, 255, 0.08);
}
.search-container:hover .search-input::placeholder {
  opacity: 100;
}
.search-container.focused .search-input::placeholder {
  opacity: 0.5;
}
.search-icon {
  color: #a2a3a6;
  transition: color 150ms ease-in-out;
}
.search-container:hover .search-icon {
  color: white;
}
.search-container.focused .search-icon {
  color: white;
}
.search-input {
  color: white;
}
.search-input::placeholder {
  color: white;
  font-size: 14px;
  opacity: 0.5;
  transition: opacity 150ms ease-in-out;
}
.new-button {
  background-color: rgba(255, 255, 255, 0.08);
  color: #cdcdce;
  transition: background-color 150ms ease-in-out;
}
.new-button:hover {
  background-color: rgba(255, 255, 255, 0.12);
}
.new-folder, .new-board {
  width: calc(50% - 4px);
  height: 32px;
  background-color: rgba(255, 255, 255, 0.08);
  transition: width 300ms ease-in-out, background-color 150ms ease-in-out;
}
.new-folder:hover, .new-board:hover {
  background-color: rgba(255, 255, 255, 0.12);
}
.new-folder.expanded, .new-board.expanded {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.08);
}
.new-folder .content, .new-board .content {
  color: #ccccce;
  transition: padding-left 300ms ease-in-out, color 300ms ease-in-out;
}
.new-folder .content, .new-board .content {
  padding-left: 20px;
}
.new-folder.expanded .content, .new-board.expanded .content {
  color: white;
}
.new-folder .content .text, .new-board .content .text {
  animation: inputFadeIn 300ms ease-in-out;
}
.quick-add-input {
  animation: inputFadeIn 300ms ease-in-out;
}
.quick-add-input::placeholder {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.44);
}
.top-fade-overlay {
  background: linear-gradient(180deg, #131119, transparent);
}
.bottom-fade-overlay {
  background: linear-gradient(0deg, #131119, transparent);
}
.hidden-boards-preview-bg {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), rgba(6, 7, 16, 0.92);
  backdrop-filter: blur(8px);
}
.fade-in {
  animation: inputFadeIn 300ms ease-in-out;
}
.input.new-folder-board::placeholder {
  color: white;
  opacity: 0.44;
  font-size: 14px;
  font-weight: 300;
}

/* ========= Vue <transition> classes ========= */
.dropdown-enter-active, .dropdown-leave-active, .checkmark-leave-active {
  transition: opacity 100ms ease-in-out;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 150ms ease-in-out;
}
.quickfade-enter-active, .quickfade-leave-active {
  transition: opacity 100ms ease-in-out;
}
.quickinput-enter-active {
  transition: opacity 100ms ease-in-out 200ms;
}
.quickinput-leave-active {
  transition: opacity 75ms ease-in-out;
}
.checkmark-enter-active {
  transition: opacity 300ms ease-in-out;
}
.fade-enter-from, .fade-enter, .fade-leave-to, .quickfade-enter-from, .quickfade-enter, .quickfade-leave-to, .dropdown-enter-from, .dropdown-enter, 
.dropdown-leave-to, .checkmark-enter-from, .checkmark-enter, .checkmark-leave-to, .quickinput-enter-from, .quickinput-enter, .quickinput-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from, .quickfade-enter-to, .quickfade-leave-from, .dropdown-enter-to, .dropdown-leave-from, 
.checkmark-enter-to, .checkmark-leave-from, .quickinput-enter-to, .quickinput-leave-from {
  opacity: 1;
}
.mainmenu-enter-active, .submenu-enter-active {
  transition: opacity 150ms ease-out, transform 150ms ease-out;
}
.mainmenu-leave-active, .submenu-leave-active {
  transition: opacity 150ms ease-out, transform 150ms ease-out;
  position: absolute;
  /* We have to account for the loss of padding when we use absolute */
  top: 0.5rem;
  right: 0.5rem;
  left: 0.5rem;
  width: calc(100% - 1rem);
}
.mainmenu-enter-from, .mainmenu-enter, .mainmenu-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}
.submenu-enter-from, .submenu-enter, .submenu-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
.mainmenu-enter-to, .mainmenu-leave-from, .submenu-enter-to, .submenu-leave-from {
  opacity: 1;
  transform: translateX(0);
}
/* ========================================== */

/* Overriding opinionated browsers */
.input {
  /* Reset default styles */
  -webkit-appearance: none;  /* Remove default styling in WebKit browsers */
  -moz-appearance: none;     /* Remove default styling in Firefox */
  appearance: none;          /* Remove default styling in modern browsers */
  padding: 0;
  margin: 0;
  border: none;
  font-size: inherit;
  background-color: transparent;
  outline: none;
}
.input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

@keyframes inputFadeIn {
  from { opacity: 0.35; }
  to { opacity: 1; }
}

/* Custom Colors */
.color-icon-normal {
  color: #b3b4b7;
}
</style>